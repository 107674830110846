import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';


const LoadingSpinner = ({ loading }) => (
    <StyledLoadingSpinner>
        {loading ? <Spinner animation="border" variant="secondary" /> : ''}
    </StyledLoadingSpinner>

)

export default LoadingSpinner;

const StyledLoadingSpinner = styled.div`
    .text-secondary {
        color: #512e80 !important;
        position: fixed;
        z-index: 1018;
        top: 25%;
        left: 50%;
    }

`