import React, { Fragment } from 'react'
import { Form } from 'react-bootstrap'

const Input = props => {
    let inputElement = null

    switch (props.inputtype) {
        case ('input'):
            inputElement = <Form.Control {...props} />
            break
        case ('textarea'):
            inputElement = <Form.Control as='textarea' rows='6' {...props} />
            break
        case ('checkbox'):
            inputElement = <Form.Check {...props} />
            break
        default: 
            inputElement = <Form.Control {...props} />
    }

    return (
        <Fragment>
            <Form.Label>{props.formlabel}</Form.Label>
            {inputElement}
        </Fragment>
    )
}

export default Input