import _, { first, update } from 'lodash'
import React, {Component, useState} from 'react'
import { useHistory } from 'react-router-dom';
import validate from "../validateForm/validateRegister";
import Register from './Register';
import { Link, NavLink } from "react-router-dom";
import axiosInstance from '../../utils/axios';
import StyledRegistrationForm from '../styling/StyledRegistrationForm'
import { motion } from "framer-motion";

const SignUp = ({updateLogic}) => {


  const [state, setState] = useState({
    email:"",
    password:"",
    passwordc:"",
    firstname:"",
    lastname:"",
    url:"",
    outlet:"",
    title:"",
    video:false,
    photo:false,
  });

  const history = useHistory();

  const [errors, setErrors] = useState({});
  const [respData, setResp] = useState();

  const handleChange = (e) => {

    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value
    const {id} = e.target;
    setState({
      ...state,
      [id]:value
    })
  }


  const handleSubmitClick = () => {
    setErrors(validate(state));
    const errors = validate(state);

    const isEmpty = _.isEmpty(errors);
    if(isEmpty){
      registerAccount();
    }
  }


  const registerAccount = () => {
    axiosInstance({
      method:'post',
      url:'/register',
      data:{
        "EmailAddress":state.email,
        "Password":state.password,
        "FirstName":state.firstname,
        "LastName":state.lastname,
        "URL":state.url,
        "Outlet":state.outlet,
        "Title":state.title,
        "AccessPhoto":state.photo,
        "AccessVideo":state.video
      }
    })
    .then(res => {
      let resData = res.data;

      if(resData == "User registered successfully"){
        //setLogIn(true);
        updateLogic("SignIn")
      }
      else{
        setResp(resData);
      }
    }).catch(err => console.log(err))
  }


  return (
    // <div onClick={()=> setLogIn(true)}>SignUp</div><StyledRegistrationForm>
    <StyledRegistrationForm>
       <motion.div className="box"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                duration: 1,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
        <h2 style={{marginTop:'50px', color:'#4a317c', marginBottom:'35px'}}>Register</h2>
            <form>
            <div className="form-group">
                <label htmlFor="exampleInputFirstName1">First Name</label>
                <input type="text" 
                       className="form-control" 
                       id="firstname" 
                       aria-describedby="firstName" 
                       value={state.firstname}
                        onChange={handleChange}
                />  
                </div>
                <p style={{color:'red'}}>{errors.firstname}</p>
                <div className="form-group">
                <label htmlFor="exampleInputLastName1">Last Name</label>
                <input type="text" 
                       className="form-control" 
                       id="lastname" 
                       aria-describedby="lastName" 
                       value={state.lastname}
                    onChange={handleChange}
                />
                </div>
                <p style={{color:'red'}}>{errors.lastname}</p>
                <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input type="text" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       value={state.email}
                       onChange={handleChange}
                />
               <p style={{color:'red'}}>{errors.email}</p>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input type="password" 
                        className="form-control" 
                        id="password" 
                        value={state.password}
                        onChange={handleChange} 
                    />
                </div>
                <p style={{color:'red'}}>{errors.password}</p>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Confirm Password</label>
                    <input type="password" 
                        className="form-control" 
                        id="passwordc" 
                        value={state.passwordc}
                        onChange={handleChange} 
                    />
                </div>
                <p style={{color:'red'}}>{errors.passwordc}</p>
                <div className="form-group">
                <label htmlFor="exampleInputURLOROUTLET1">Outlet</label>
                <input type="text" 
                       className="form-control" 
                       id="outlet" 
                       aria-describedby="outet" 
                       value={state.outlet}
                       onChange={handleChange}
                />
                </div>
                <p style={{color:'red'}}>{errors.outlet}</p>
                <div className="form-group">
                <label htmlFor="exampleInputURLOROUTLET1">Url</label>
                <input type="text" 
                       className="form-control" 
                       id="url" 
                       aria-describedby="url" 
                       value={state.url}
                       onChange={handleChange}
                />
                </div>
                <p style={{color:'red'}}>{errors.url}</p>
                <div className="form-group">
                <label htmlFor="exampleInputURLOROUTLET1">Title</label>
                <input type="text" 
                       className="form-control" 
                       id="title" 
                       aria-describedby="title" 
                       value={state.title}
                       onChange={handleChange}
                />
                </div>
                <div>
                <p style={{color:'red'}}>{errors.title}</p>
                    <p>Request access to</p>
      <label>
        <input type="checkbox" id="video" check={state.video} onChange={handleChange} style={{margin:'10px'}}/>
        Videos
      </label>
    </div>
    <div>
      <label>
        <input type="checkbox" id="photo" check={state.photo} onChange={handleChange} style={{margin:'10px'}} />
        Photos
      </label>
    </div>
                <button 
                    type="button" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                    style={{marginBottom:'35px', marginTop:'20px'}}
                >
                    Register
                </button>
            </form>
            
            <p style={{color:'red'}}>{respData}</p>
            <div className="mt-2" style={{paddingBottom:'50px'}}>
                {/* <span>Already have an account? <Link to={'/Login'} style={{color:"#4a317c", fontWeight:'bold'}}>Sign in</Link></span> */}
                {/* <span className="loginText" onClick={() => redirectToLogin()}>Login here</span>  */}
                <span>Already have an account? <span style={{color:"#4a317c", fontWeight:'bold', cursor:'pointer'}} onClick={() => updateLogic("SignIn")}>Sign in</span></span>
            </div>
            
        </div>
        </motion.div>
        </StyledRegistrationForm>
  )
}


export default SignUp;
