import React, {useState, useContext, Fragment} from 'react'
import {useHistory} from 'react-router-dom';
import { Button, Container, Form } from "react-bootstrap";
import ContextApi from '../../ContextApi';
import StyledLogin from "../styling/StyledLogin";
import axiosInstance from '../../utils/axios';
import { Link, NavLink } from "react-router-dom";
import SignUp from './SignUp';
import { motion } from "framer-motion";
import { update } from 'lodash';


const WelcomeRightSide = ({updateLogic}) => {

  const { setAuth } = useContext(ContextApi);
  const initialInputState = {email: "", password:""};
  const [state , setState] = useState({
      email : "",
      password : "",
      successMessage: null
  })
  const [loginResp, setLoginResp] = useState([]);
  const history = useHistory();

  const handleChange = (e) => {
      const {id , value} = e.target   
      setState(prevState => ({
          ...prevState,
          [id] : value
      }))
  }

  const ResetPassword = () => {
      //history.push("/ForgotPassword");
      updateLogic("ResetPassword");
  }


  const onSubmit = () => {
      axiosInstance({
          method:'post',
          url:'/auth',
          data: {
              Username: state.email,
              Password: state.password,
          }
      })
      .then(res => {
          let resData = res.data;
          //console.log(resData);
          if(resData.email){
              localStorage.setItem('user', JSON.stringify(resData));
              setAuth(true);
              // setAccessPhoto(resData.accessPhoto);
              // setAccessVideo(resData.accessVideo);
              history.push("/hallmark-channel");

          }

          else{
              setLoginResp(resData);
          }
          
      }).catch(err => console.log(err))
  };

  return (
    
        <div style={style.rightSide}>
            <motion.div className="box"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                duration: 1,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
        <h2>Member Login</h2>
            <form>
                <div className="form-group text-left">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input type="email" 
                       className="form-control" 
                       id="email" 
                       aria-describedby="emailHelp" 
                       value={state.email}
                        onChange={handleChange}
                />
                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                </div>
                <div className="form-group text-left">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input type="password" 
                       className="form-control" 
                       id="password" 
                       value={state.password}
                        onChange={handleChange} 
                />
                </div>
                <div className="form-check">
                </div>
                
                <button 
                    type="button" 
                    className="btn btn-primary"
                    onClick={onSubmit}
                >Submit</button>
                <button 
                    type="button" 
                    className="btn btn-primary"
                     style={{marginLeft:'10px', borderColor:'#4a317c', backgroundColor:'white', color:'black'}} onClick={() => updateLogic("ForgotPassword")}
                >Forgot Password</button>
               
            </form>
            <div className="alert mt-2" style={{color:'red'}} role="alert">
                {loginResp}
            </div>
            <div className="registerMessage">
                <span>Dont have an account? <span onClick={() => updateLogic("SignUp")} style={{fontWeight:'bold', color:'#4a317c', cursor:'pointer', }}>Sign Up Today</span></span>
                {/* <span className="loginText" onClick={() => redirectToRegister()}>Register</span>  */}
            </div>
            </motion.div>
        </div>

  )
}


  const style = {
    rightSide: {
      margin:'0px auto',
      paddingRight:'40px',
      paddingTop:'250px',
      paddingLeft:'50px',
      marginBottom:'150px',
   
    }
  }



export default WelcomeRightSide;