import styled from 'styled-components';
import media from './media';



const StyledLogin = styled.div`

.card {
  max-width: 40%;
  padding-bottom: 150px;
  margin: auto;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: none;
  background-color: #fff;
  
}

h2 {
  color: #512e80;
  margin-bottom: 2rem;
  margin-top:100px;
}

.form-group {
  color: #2F4F4F;

}

.btn-primary {
  background-color: #4a317c;
}






`
export default StyledLogin;