import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router';
import { Container, Button } from 'react-bootstrap';
import axiosInstance from '../../utils/axios';
import StyledProgramming from '../styling/StyledProgramming';
import SelectNetwork from './SelectNetwork';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faBars} from "@fortawesome/free-solid-svg-icons";
import SelectLetter from './SelectLetter';
import { useParams } from 'react-router';

const StuntLandingPage = () => {

    let { stunt } = useParams();

    const history = useHistory();
    const [StuntData, setStuntData] = useState({});
    const [Banner, setBanner] = useState();
    const [Thumbnails, setThumbnails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ThreeColumn, setThreeColumn] = useState(false);

    let { channel } = useParams()

    useEffect(() => {

     

        // console.log(talent);
        const fetchProgrammingData = async () => {
            setLoading(true);
            const api = `/StuntsLandingPage?Stunt=${stunt}`;
            const res = await axiosInstance
                .get(api)
                .then(res => {
                    let resData = res.data.result;
                    console.log(resData)
                    const thumbnails = [...resData.thumbnails].sort((a, b) => {
                            return a.sort - b.sort
                        });
                    // const resData = [...resd].sort((a, b) => {
                    //     return a.sort - b.sort
                    // });
                    //console.log(resData);
                    setStuntData(resData);
                    setBanner(resData.bannerImg);
                    
                    setThreeColumn(resData.columns);
                    setThumbnails(thumbnails);
                })

        }
        
        fetchProgrammingData();
    },[]);

    const HandleClick = (data) => {
       
        // console.log(data.url);
        if(data.url.includes(".com")){
            
            <Redirect to={data.url}></Redirect>
        }

        else{
            history.push(`/hallmark-channel/programming/program=${data.url}&channel=${channel}`);
        }

    }


    const programmingList = Thumbnails.map((data, i) => { 
        
        // var url = data.url;

        // if(data.url == null){
        //     //console.log("No URL for ", data.title);
        //     console.log("");
        // }

        // else if(data.url.includes(".com") && data.url != null){
        //     url = data.url;
        // }

        // else{
        //     //url = `http://press-stage.cminternalcontrol.com/hallmark-channel/programming/program=`+ data.url;
        //     url = `https://www.crownmediapress.com/hallmark-channel/programming/program=`+ data.url;
        // }


        var url = data.url;
        // console.log(data);

        if(data.url == null){
        // console.log("No URL for ", data.title);
        console.log("");
        }

        else if(data.url.includes(".com") && data.url != null){
        if(data.url.includes("crownmediapress.com")){
            var splitted = data.url.split(".com");
            url = `https://press.hallmarkmedia.com` + splitted[1] + `&channel=${channel}`;
        }
        else{
            url = data.url;
        }
        }

        else{
        //url = `https://www.crownmediapress.com/hallmark-channel/programming/program=`+ data.url;
        url = `https://press.hallmarkmedia.com/hallmark-channel/programming/program=`+ data.url + `&channel=${channel}`;
        }


        //Checks to see if 3 columns was selected when creating a stunt landing page (banner). If selected, each row
        //will have 3 columns, else there will be 4 per column
        var colSize ="col-md-3 col-sm-6 col-xs-12 p-md isotope-item"
        if(ThreeColumn == true){
            colSize = "col-md-4 col-sm-6 col-xs-12 p-md isotope-item"
        }
        
        return (<li className={colSize} key={i}>
            <div className="listing-item">
                <a className="text-decoration-none">
                    <span className="thumb-info thumb-info-lighten">

                        <span className="thumb-info-wrapper m-none btnPhotoDisabled">
                            <section className="keyart_section">
                                <div className="img-hover-zoom img-hover-zoom--xyz">
                                <a href={url}>
                                    <img src={data.img} alt={data.title}/> 
                                </a>
                                </div>
                                <p className='show-title'>{data.title}</p>
                            </section>
                        </span>
                    </span>
                </a>
            </div>
        </li>
        )
    })

    return (
        <StyledProgramming>
            <Container>
                
                <div className="row">
                    <div className='col-12'>
                    <img src={Banner} style={{marginTop:'10px', marginBottom:'10px'}}/> 
                    </div>
                    <div className="col-md-12">
                        <ul id="listingLoadMoreWrapper" className="properties-listing sort-destination p-none" data-total-pages="2">
                            {programmingList}
                        </ul>
                    </div>
                </div>
            </Container>
        </StyledProgramming>
    )
}


export default StuntLandingPage;
