import React, {useState,useEffect} from 'react'
import { Container, Button, Row, Col} from 'react-bootstrap';
import WelcomeLeftSide from './WelcomeLeftSide';
import WelcomeRightSide from './WelcomeRightSide';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';

const Welcome = () => {

  const [signUp, setSignUp] = useState(true);
  
  const [rightSideLogic, setLogic] = useState("SignIn")
 
  useEffect(() => {

  },[signUp])


  const updateLogic = (logic) => {
      setLogic(logic)

  }

  const rightSide = () => {

      if(rightSideLogic == "SignIn") {
        return (<WelcomeRightSide updateLogic={updateLogic}></WelcomeRightSide>)
      }
      
      else if(rightSideLogic == "SignUp"){
        return (<SignUp updateLogic={updateLogic}></SignUp>)
      }

      else if(rightSideLogic == "ForgotPassword"){
        return (<ForgotPassword updateLogic={updateLogic}></ForgotPassword>)
      }

      else {
        return (<WelcomeRightSide updateLogic={updateLogic}></WelcomeRightSide>)
      }
  }
  

  //Used to determine if the user is looking to sign into the website, or sign up for the service
  const signUpLogIn = () => {
    setSignUp(!signUp);
  }

  return (
    <div className='container-fluid' style={{paddingLeft:'0px'}}>
      <div className='row'>
        <div className='col-sm-8 d-none d-lg-block'>
          <WelcomeLeftSide signUp={signUp}></WelcomeLeftSide>
        </div>
        <div className='col-sm-12 col-lg-4'> 
          {rightSide()}
        </div>
      </div>
      
    </div>
  )
}


export default Welcome; 
