import { css } from 'styled-components'

const sizes = { 
  mobileS: 320, 
  mobileM: 375, 
  mobileL: 425,  
  tablet: 768, 
  tabletL: 1024, 
  laptop: 1440, 
  full4k: 2560, 
}

export default Object.keys(sizes).reduce((acc, key) => {
    acc[key] = (...args) => css`
    @media (min-width: ${sizes[key]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})
