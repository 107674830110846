import styled from 'styled-components';
import media from '../styling/media';


const StyledHolidayLights = styled.div`
.lights {
    width:1140px;
    padding-bottom:0px;
  
  margin: 0 auto;
  position: relative;
}




[css] @media (min-width: 1100px){
    .lights {
        width:200px;
        padding-bottom:0px;
        margin: 0 auto;
        position: relative;
    }
    
   
}
@media (min-width: 0px){
    .lights{

        width:1px;
       
    }


}

@media (min-width: 990px){
    .lights{

        width:1140px;
        padding-right:150px;
        padding-bottom:0px;
        margin: 0 auto;
        position: relative;
    }


}
    
    @media (min-width: 1200px){
        .lights{

            width:1140px;
            padding-right:0px;
            padding-bottom:0px;
            margin: 0 auto;
            position: relative;
        }
    

    
    
    
    }

    `
export default StyledHolidayLights;