import styled from 'styled-components';
import media from '../styling/media';

const StyledNavBar = styled.div`
    //background-color: #4A317C;
    background-color: #9a0c28;
    color: #fff;

    img {
    padding-bottom: 15px;
    width: 200px
}

.networkNav-bg {
        //background: #4A317C;
        background-color: #9a0c28;
    }

    .navbar-light .navbar-nav .nav-link, .navbar-light .navbar-toggler, a {
        
        
        &:hover {
            //color: #F1C400;
            color: #9a0c28;
            text-decoration: none;
            
        }

        &:active, &.active {
            //color: #F1C400;
            color: #9a0c28;
            text-decoration: none;      
            
        }

    }

    .mr-auto {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0;
    }

    .network_container, .account_container {
        display: flex;
        flex-direction: row;
    }

    .network-logo {
        padding: 0 3px ;
    }

    .container { 
        padding: 0;
    }

    .navbar {
        padding: 0;
    }

    .navbar-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    

    a.dropdown-item {
        text-transform: uppercase;
    }
    
    a.dropdown-item:hover {
        //background-color: #4A317C;
        background-color: #9a0c28;
    }

    a.dropdown-item.active, a.dropdown-item:active {
        //background-color: #4A317C;
        background-color: #9a0c28;
        color: #F1C400;
    }

    @media (max-width: 992px) {

        .navbar-light .navbar-nav .nav-link, .navbar-light .navbar-toggler, a {
            
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            text-align: center;
        
            &:hover {
                //color: #F1C400;
                color: #FAA61A;
                text-decoration: none;
                
            }
    
            &:active, &.active {
                //color: #F1C400;
                color: #FAA61A;
                text-decoration: none;      
                
            }
    
        }

        .navbar-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            text-align: center;
        }
        }

`

export default StyledNavBar;