import styled from 'styled-components';
import media from '../styling/media';



const StyledProgramming = styled.div`

ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
}

.thumb-info-wrapper {
    width: 100%;
}

.show-title {
    text-align: center;
    word-wrap: normal;
}

img {
    width: 100%;
}

//image zoom
.keyart_section {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
}

.img-hover-zoom {
    margin: 5px;
    height: auto; /* [1.1] Set it as per your need */
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */

    /* [3] Finally, transforming the image when container gets hovered */
    &:hover img {
      transform: scale(1.07);
    }
  }

  /* [2] Transition property for smooth transformation of images */
  .img-hover-zoom img {
    transition: transform 0.5s ease;
  }

  //image zoom



`
export default StyledProgramming;