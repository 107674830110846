import React from 'react'
import { Container, Tabs, Tab, Form, Dropdown, DropdownButton, Button} from 'react-bootstrap';

//Used as a letter pagination
const SelectLetter = (props) => {

    const {filterLetter, currentLetterFilter, allFilterLetters} = props;

    return (
        <>
        <div role="group" aria-label="Basic example" style={styling}>
            {allFilterLetters.map((data, i) => (
                <button type="button" class="btn btn-secondary" style={currentLetterFilter === data ? activeButtonStyling : buttonStyling} onClick={() => filterLetter(data)}>{data}</button>
            ))}
        </div>
        <Dropdown.Divider></Dropdown.Divider>
        </>
    )
}

export default SelectLetter;

const styling = {
    paddingLeft:'5%',
    paddingBottom:'20px',

}

const buttonStyling = {
    color:"black",
    backgroundColor:"white",
    borderColor:"white",
    fontSize:'smaller',
    fontSize:'16px'
}

const activeButtonStyling = {
    color:"black",
    backgroundColor:"white",
    borderColor:"white",
    fontWeight:'bold',
    fontSize:'x-large',
    fontSize:'27px',
    margin:'10px'
}
