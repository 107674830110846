import { createGlobalStyle } from "styled-components";

export const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
  }

  .content-article {
    min-height: calc(100vh - 35vh);
  }

  .container {
    padding-right:0px !important;
    padding-left:0px !important;
  }
`;

export default Global;