import React, { useContext , useState} from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import ContentRouter from "./container/routing/ContentRouter";
import Layout from "./container/layout/Layout";
import Footer from "./container/layout/Footer";
import Global from './container/styling/Global'
import NetworkNavbar from './container/layout/NetworkNavbar';
import NavigationBar from './container/layout/NavigationBar';
import { useParams } from 'react-router';
import { update } from 'lodash';
import ContextApi from './ContextApi';
import Welcome from './container/components/Welcome';
// import ContextApi from "./ContextApi";



const App = () => {

  const [auth, setAuth] = useState(localStorage.getItem("user") ? true : false);
  //Used for search bar
  const [allPrograms, setAllPrograms] = useState([]);
  const [allTalent, setAllTalent] = useState([]);
  const [currentTab, setCurrentTab] = useState();
  const user = JSON.parse(localStorage.getItem("user"));

  //Used for ShowAllImages Page
  const[currentShowAllImages, setShowAllImages] = useState([]);

  const [currentChannel, setCurrentChannel] = useState("hallmark-channel");

  // const updateChannelSelected = (channel) => {
  //   setCurrentChannel(channel);
  // }

  return (
    <>
      {/* <ContextApi.Provider
        value={{newsData
        }}
      > */}

      <ContextApi.Provider value = {{
        auth,
        setAuth,
        user,
        allPrograms,
        setAllPrograms,
        currentShowAllImages,
        setShowAllImages,
        allTalent,
        setAllTalent,
        currentChannel,
        setCurrentChannel,
        currentTab,
        setCurrentTab

      }}>
        <Router>
          <Layout>
           {auth ? <><NetworkNavbar/><NavigationBar currentChannel={currentChannel}/><ContentRouter /> </> : <Welcome/>} 
           {/* <NetworkNavbar/><NavigationBar currentChannel={currentChannel}/><ContentRouter /> */}
          </Layout>
          <Footer />
        </Router>
        <Global />
        </ContextApi.Provider>
      {/* </ContextApi.Provider> */}

    </>

  );
};

export default App;