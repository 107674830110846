import { text } from '@fortawesome/fontawesome-svg-core';
import React, {useState}from 'react'
import axiosInstance from "../../utils/axios";
import StyledRegistrationForm from '../styling/StyledRegistrationForm'
import StuntLandingPage from './StuntLandingPage';

const ForgotPassword = ({updateLogic}) =>{

    const [state, setState] = useState({
        email:""
    })

    const [resp, setResp] = useState();


    const handleChange = (e) => {
        const {id, value} = e.target 
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const submitRequest = () => {
        axiosInstance({
            method:'post',
            url:'/resetpassword',
            data: {
                Email:state.email
            }
        })
        .then(res => {
            let resData = res.data;
            //console.log(resData);
            setResp(resData);
            
            

            
        }).catch(err => console.log(err))
    };





    return (
        <StyledRegistrationForm>
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
        <h2 style={{marginTop:'270px', color:'#4a317c', marginBottom:'35px'}}>Reset Password</h2>
            <form>
                <div className="form-group">
                    <label htmlFor="email">User Email</label>
                    <input type="text" 
                        className="form-control" 
                        id="email" 
                        aria-describedby="email" 
                        value={state.email}
                            onChange={handleChange}
                    />  
                </div>
            </form>
            {/* <button 
                    type="button" 
                    className="btn btn-primary"
                    onClick={submitRequest}
                    style={{marginBottom:'35px', marginTop:'20px', width:'150px'}}
                >
                    Reset Password
                </button>
                <button 
                    type="button" 
                    className="btn btn-primary"
                    onClick={() => updateLogic("SignIn")}
                    style={{marginBottom:'35px', marginTop:'20px', width:'150px'}}
                >
                    Sign In
                </button> */}
                <div style={{display:'flex', justifyContent:'center'}}>
                <button 
                    type="button" 
                    className="btn btn-primary"
                    onClick={submitRequest}
                    style={{display:"flex", justifyContent:'center'}}
                >Reset Password</button>
                </div>
                <div style={{display:'flex', justifyContent:'center'}}>
                <button 
                    type="button" 
                    className="btn btn-primary"
                     style={{marginTop:'10px', borderColor:'#4a317c', backgroundColor:'white', color:'black'}} onClick={() => updateLogic("Sign In")}
                >Sign In</button>
                </div>
        </div>
        <p style={{textAlign:'center'}}>{resp}</p>
        </StyledRegistrationForm>
    )
}


export default ForgotPassword;
