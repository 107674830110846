const validateRegister = (values) => {
    let errors = {}

    // if (values.promotional === false) {
    //     errors.promotional = 'Please indicate that you have acknowledge and agree to the Terms and Conditions and Privacy Policy'
    // } 

    // if (values.technical === false) {
    //     errors.technical = 'Please indicate that you have acknowledge and agree to the Terms and Conditions and Privacy Policy'
    // } 
  
    if (!values.firstname) {
        errors.firstname = 'First name is required.'
    } else if (!/^[a-z ,.'-]+$/i.test(values.firstname)) {
        errors.firstname = 'First name is invalid.'
    }

    if (!values.lastname) {
        errors.lastname = 'Last name is required.'
    } else if (!/^[a-z ,.'-]+$/i.test(values.lastname)) {
        errors.lastname = 'First name is invalid.'
    }

    if (!values.title) {
        errors.title = 'Job title is required.'
    } else if (!/^[a-z ,.'-]+$/i.test(values.title)) {
        errors.title = 'Job title is invalid.'
    }

    if (!values.email) {
        errors.email = 'Email address is required.'
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid.'
    } 

    if (!values.password) {
        errors.password = 'Password is required.'
    } else if (!/^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*([^\w\s]|[_]))\S{5,}$/.test(values.password)) {
        errors.password = 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.'
    }

    if (!values.passwordc) {
        errors.passwordc = 'Confirm password is required.'
    } else if (values.passwordc !== values.password) {
        errors.passwordc = 'Confirm password does not match.'
    }

    if (!values.outlet) {
        errors.outlet = 'Company name is required.'
    } else if (values.outlet.length < 3) {
        errors.outlet = 'Company name is required.'
    }

    if (!values.url) {
        errors.url = 'Url is required'
    } else if (values.url.length < 3) {
        errors.url = 'Url is required'
    }

    return errors
}

export default validateRegister;