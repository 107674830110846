import styled from 'styled-components';
import media from '../styling/media';



const StyledRegistrationForm = styled.div`

.card {
  max-width: 50%;
  padding-bottom: 10px;
  margin: auto;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: none;
  background-color: #fff;
  
}

.form-group {
  color: #2F4F4F;

}

.btn-primary {
  background-color: #4a317c;
  padding-bottom; 60px;
}






`
export default StyledRegistrationForm;