import styled from 'styled-components';
import media from '../styling/media'

const StyledSearchBar = styled.div`
    display: flex;
    margin: 0;

    a.accordion.nav-link {        
        &.active, &:active {
            border-bottom: 0;
        }
    }

    .accordion__content {
        display:flex;
        flex-direction: row;
        overflow: hidden;
        transition: width .7s ease;
    }

    .search.form-control {
        box-shadow: none;
        border: 1px solid #6621A9;
        border-left: 0;
    }

    .searchContainer {
        border: 1px solid #ddd;
        position: absolute;
        top: 73px;
        width: 247px;
        max-height: 22vh;
        overflow: hidden;
        overflow-y: scroll;
    }

    .option {
        background-color: #fff;
        color: rgba(0,0,0,.5);;
        padding: 5px;
        display: block;
        cursor: pointer;

        &:hover {
            background-color: #efefef;
            color: #512e80;
        }
    }

    label.form-label {
        display: none;
    }

    ${media.mobileS`   
        .accordion__content {
            margin: 0;
        }

        .form-group-content {
            margin-bottom: 0 !important;
            padding-left: 0;
            padding-right: 0;
        }

        .searchContainer {
            position: absolute;
            top: 237px;
            width: 231px;
            font-size: 16px;
            max-height: 24vh;
            margin-left: 29px;
            border-radius: 5px;
            z-index: 50;
        }

        .search.form-control {
            box-shadow: none;
            border: 1px solid #6621A9;
            border-left: 0;
            width: 230px;
        }

        #search-style::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3, 0);
        background-color: #fff;
        }

        #search-style::-webkit-scrollbar{
            width: 10px;
            background-color: #fff;
        }

        #search-style::-webkit-scrollbar-thumb{
            background-color: #5b437a;
            border-radius: 2px;
            background-image: -webkit-gradient(linear,
                                        left bottom,
                                        left top,
                                        color-stop(0.44, #512e80),
                                        color-stop(0.55, #6621A9),
                                        color-stop(0.80, #6621A9));
        }


        a.underline.search-btn.nav-link {
            line-height: 1.5em;
        }

        a.underline.close-btn.nav-link {
            font-size: 20px;
            display: flex;
        }

        .form-control {
            height: calc(1.5em + .75rem + 3px);
            border: 1px solid #6621A9;
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .input-group-text {
            height: calc(1.5em + .75rem + 3px);
            padding-right: 0;
            border: 1px solid #6621A9;
            border-right: 0;
            border-radius: 5px;
            background-color: #fff;
            color: #6621A9;
        }

    `}

    ${media.mobileM`    
        .searchContainer {
            width: 275px;
            margin-left: 34px;
        } 

        .search.form-control {
            width: 280px;
        }
    `}

    ${media.mobileL`    
        .searchContainer {
            width: 325px;
        } 

        .search.form-control {
            width: 330px;
        }
    `}

    ${media.tablet`    
        .searchContainer {
            width: 396px;
        } 

        .search.form-control {
            width: 400px;
        }
    `}

    ${media.tabletL`    
        .searchContainer {
            top: 56px;
            width: 229px;
            margin-left: 0;
        } 

        .search.form-control {
            width: 200px;
        }

        .fas.fa-times {
            line-height: 1.25em;
        }

    `}

    ${media.laptop`    
        .searchContainer {
            width: 325px;
            margin-left: 30px;
        } 

        .search.form-control {
            width: 325px;
        }
    `}

`


export default StyledSearchBar;