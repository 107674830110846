import React , {useEffect, useState, useContext}from 'react';
import { Link, NavLink } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Container } from "react-bootstrap";
import StyledNavBar from '../styling/StyledNavBar';
import ContextApi from '../../ContextApi';
import StyledHolidayLights from '../styling/StyledHolidayLights';


const NavigationBar = () => {

    //const {currentChannel} = props;
    const { auth, user, currentChannel, setCurrentChannel, currentTab, setCurrentTab} = useContext(ContextApi);

    const home = `/${currentChannel}`;
    const news = `/${currentChannel}/news`;
    const programming = `/${currentChannel}/programming`;
    const images = `/${currentChannel}/images`;
    const videos = `/${currentChannel}/videos`;
    const requests = `/hallmark-channel/mediarequests`;

    const triggerTab = (tab) => {
        setCurrentTab(tab);
    }

    return (
        <>
        {/* style={{boxShadow:'0px 7px 9px 0px #848484'}} */}
        {/* Added Paddingtop and boxShadow to nav */}
            <StyledNavBar>
                <nav className="navbar-header subnav" style={{paddingTop:'20px', boxShadow:'inset 0 10px 10px -10px #000000'}}>
                    <Container className='HC-container'>
                        <Navbar collapseOnSelect expand="lg">
                            <Navbar.Toggle aria-controls="basic-navbar-nav"  />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto HC-nav">
                                    <Navbar.Brand>
                                        <Link className="navbar-brand" to={home}>
                                            <img
                                                className="nav-logo"
                                                src="/images/HallmarkMedia_Logo_White.png"
                                                alt="Hallmark Channel Logo"
                                                style={{width:'150px'}}
                                            />
                                        </Link>
                                    </Navbar.Brand>
                                    <div className='navbar-list' >
                                        <NavDropdown.Item to={news} as={NavLink} style={currentTab == "News" ? styleClicked : styleUnclicked} onClick={() => triggerTab("News")}>
                                            News
                                    </NavDropdown.Item>
                                        <NavDropdown.Item to={programming} as={NavLink} style={currentTab == "Programming" ? styleClicked : styleUnclicked} onClick={() => triggerTab("Programming")}>
                                            Programming
                                    </NavDropdown.Item>
                                        <NavDropdown.Item to={images} as={NavLink} style={currentTab == "Images" ? styleClicked : styleUnclicked} onClick={() => triggerTab("Images")}>
                                            Images
                                    </NavDropdown.Item>
                                        {/* <NavDropdown.Item to={videos} as={NavLink}>
                                            Screening Room
                                    </NavDropdown.Item> */}
                                    <NavDropdown.Item href="https://corporate.crownmedia.com/#/networks" target="_blank" style={styleUnclicked}>
                                            About
                                    </NavDropdown.Item>
                                    {auth ? <NavDropdown.Item href='https://cm-press-site-2021.s3.amazonaws.com/NetworkContacts2021.pdf' target="_blank"  style={styleUnclicked}>
                                            Network Contacts
                                    </NavDropdown.Item> : null}
                                    <NavDropdown.Item href="https://corporate.crownmedia.com/#/" target="_blank"  style={styleUnclicked}>
                                            Corporate
                                    </NavDropdown.Item>
                                   
                                    <NavDropdown.Item to={requests} as={NavLink} style={currentTab == "Requests" ? styleClicked : styleUnclicked} onClick={() => triggerTab("Requests")}>
                                            Requests
                                    </NavDropdown.Item>
                                    
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                        {/* HolidayLights */}
                    {/* <StyledHolidayLights>
                    { <img className="lights"         
                            src="/images/Christmas_Red_Green_Leaf.gif"
                            //src = "/images/PR22_Loveuary_GIF_v2.gif"
                            alt="Hallmark Channel Logo"
                        /> }
                    </StyledHolidayLights> */}
                    </Container>
                </nav>
            </StyledNavBar>
        </>
    );
};


const styleUnclicked = {
    fontWeight:'bolder',
    color:'white'

};

const styleClicked = {
    fontWeight:'bolder',
    color:'#e0b221'
}

const holidayLights = {
    width:'1140px',
    paddingBottom:'0px'
}


export default NavigationBar;